<template>
  <el-row class="bg-white radius-12 px-20 py-12">
    <el-col :span="12" :xs="8" :sm="13" :lg="15" :xl="17">
      <img src="@/assets/img/laba.png" class="icon-laba" alt="">
      <div class="carouse-text inline-block">
        <span>网络不是法外之地，请注意您的言辞，如AI一直请求繁忙，请过段时间提问</span>
      </div>
    </el-col>
    <el-col :span="12" :xs="16" :sm="11" :lg="9" :xl="7">
      <div class="right flex-row flex-end">
        <div @click="sign">每日签到</div>
        <div v-if="!userInfo.active" class="linear-text" @click="setActiveBoxVisible('2')">输入激活码</div>
        <div v-if="!userInfo.vip" @click="openVipBtn">开通会员</div>
        <el-popover placement="bottom-start" :width="200" trigger="click" :hide-after="50">
          <template #reference>
            <div class="m-2">客户端下载</div>
          </template>
          <el-image src="https://wailikeji.oss-cn-beijing.aliyuncs.com/chatgpt/sys/chatgpt/appDownloadUrl.jpg"></el-image>
        </el-popover>
        <div v-if="!userInfo.nickName" @click="login">登录</div>
        <div v-else @click="userToggleBtn">
          {{ userInfo.nickName }}
          <div class="user-box inline-block">
            <el-icon :size="16">
              <i-ep-caret-bottom />
            </el-icon>
            <div class="user-info-box" v-show="userVisible">
              <div class="user-item" @click="loginOut">退出登录</div>
            </div>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
  <loginDialog></loginDialog>
</template>

<script >
import loginDialog from './login.vue'
import { SignIn, activeUser, getUserInfo } from '@/http/http.js'
import { mapState, mapActions } from 'pinia'
import { globalData } from '@/store/store'
import { ACTIVE_MSG } from '@/view/home'

export default {
  data() {
    return {
      userVisible: false,
    }
  },
  components: {
    loginDialog
  },
  computed: {
    ...mapState(globalData, ['userInfo', 'token', 'activeBoxVisible'])
  },
  watch: {
    'activeBoxVisible': {
      handler() {
        this.showActiveMethodDialog()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(globalData, ['setLoginVisible', 'setToken', 'setUserInfo', 'setActiveBoxVisible']),
    userToggleBtn() {
      this.userVisible = !this.userVisible;
    },
    showActiveMethodDialog() {
      // 可能为字符串1，不要用全等符号
      if (!this.token || this.activeBoxVisible === '1') {
        return;
      }
      // 会员和已激活用户不弹窗
      if (this.userInfo && (!this.userInfo.active || !this.userInfo.vip)) {
        this.setActiveBoxVisible('1');
        ElMessageBox.prompt(ACTIVE_MSG, '提示', {
          confirmButtonText: '激活',
          cancelButtonText: '取消',
          inputPattern: /[\S]{3,16}/,
          inputErrorMessage: '请输入长度16位以内的激活码',
          type: 'info',
          dangerouslyUseHTMLString: true,
          confirmButtonClass: 'linear-border',
          customClass: 'waili-share-msg-box'
        }).then(({ value }) => {
          activeUser({ code: value }).then(() => {
            ElMessage.success('激活用户成功')
          })
        }).catch(() => {
          // 捕获cancel异常
        })
      }
    },
    login() {
      if (!this.userInfo.nickName) {
        this.setLoginVisible(true);
      }
    },
    loginOut() {
      this.setToken('')
      this.setUserInfo({})
      location.reload()
    },
    openVipBtn() {
      this.$router.push({ path: `/home/recharge` })
    },
    sign() {
      SignIn().then(res => {
        ElMessage.success(res.data);
      })
    }
  },
  created() {
    getUserInfo().then(res => {
      this.setUserInfo(res.data)
    })
  }
}
</script>

<style scoped lang="scss">
.icon-laba {
  width: 20px;
  height: 20px;
  line-height: 30px;
  vertical-align: super;
  margin-right: 5px;
}

.carouse-text {
  color: $blue;
  width: calc(100% - 25px);
  position: relative;
  overflow: hidden;
  height: 30px;
  line-height: 30px;

  @keyframes move {
    0% {
      left: 100%
    }

    100% {
      left: -130%
    }
  }

  span {
    position: absolute;
    top: 0;
    animation: move 8s linear infinite;
  }
}

.right {
  white-space: nowrap;

  >div {
    margin-left: 12px;
    cursor: pointer;
    line-height: 30px;

    &:hover {
      color: $blue;
    }
  }
}

.user-box {
  position: relative;
  margin-left: 0;

  .user-info-box {
    position: absolute;
    top: 100%;
    right: 0px;
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: 0 3px 4px #E5E5E5;

    .user-item {
      height: 30px;
      line-height: 30px;
      width: 120px;
      text-align: center;
      color: #333333;

      &:hover {
        background-color: rgba($blue, .2);
        color: $blue;

      }
    }
  }


}
</style>