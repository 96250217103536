import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from "pinia";
// 创建pinia实例
const pinia = createPinia()
import router from './router/router.js'
import 'element-plus/dist/index.css';
import ElementPlus from 'element-plus'
import 'highlight.js/styles/atom-one-dark.css'
import 'highlight.js/lib/common'
import hljsVuePlugin from '@highlightjs/vue-plugin'
import locale from "element-plus/lib/locale/lang/zh-cn";

createApp(App)
  .use(ElementPlus, { locale })
  .use(router)
  .use(hljsVuePlugin)
  .use(pinia)
  .mount('#app')
