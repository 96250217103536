import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/home/home',
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/home/home',
    component: () => import('../view/layout/layout.vue'),
    children: [
      {
        path: '/home/home',
        name: 'homeIndex',
        component: () => import('../view/home/home.vue'),
      },
      {
        path: 'paint',
        name: 'homePaint',
        component: () => import('../view/paint/paintTab.vue'),
      },
      {
        path: 'paint/detail/:id',
        name: 'paintDetail',
        component: () => import('../view/paint/components/drawDetail.vue'),
      },
      {
        path: '/home/chatpdf',
        name: 'chatpdf',
        component: () => import('../view/chatpdf/chatpdf.vue'),
      },
      {
        path: '/home/myPaint',
        name: 'myPaint',
        component: () => import('../view/myPaint/myPaint.vue'),
      },
      {
        path: '/home/issureLog',
        name: 'issureLog',
        component: () => import('../view/issureLog/issureLog.vue'),
      },
      {
        path: '/home/issureTmp',
        name: 'issureTmp',
        component: () => import('../view/issureTmp/issureTmp.vue'),
      },
      {
        path: '/home/coinLog',
        name: 'coinLog',
        component: () => import('../view/coinLog/coinLog.vue'),
      },
      {
        path: '/home/recharge',
        name: 'recharge',
        component: () => import('../view/recharge/recharge.vue'),
      },
      {
        path: '/home/user',
        name: 'userCenter',
        component: () => import('../view/user/user.vue'),
      },
      {
				path: '/home/shortVideo',
				name: 'shortVideo',
				component:  () => import('../view/shortVideo/shortVideo.vue'),
			},
      {
				path: '/home/virtualRobot',
				name: 'virtualRobot',
				component:  () => import('../view/virtualRobot/virtualRobot.vue'),
			},
    ]
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../view/about/about.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
