import { defineStore } from 'pinia'

export const pdfData = defineStore({
  id: 'pdfData',
  state: () => ({
    // 创作同款图片参数
    knowledgeId: null,
  }),
  getters: {
  },
  actions: {
    setKnowledgeId(val) {
      this.knowledgeId = val;
    },
  }
})